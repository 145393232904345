import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ExternalLink from '../../../components/ExternalLink';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/services/erpnext/accessibility",
  "title": "Accordion - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Use HDS provided accordion elements when creating custom accordions. These elements pass accessibility testing.`}</li>
      <li parentName="ul">{`When creating custom accordions, make sure that the expanded accordion area directly follows the accordion button in the DOM tree.`}
        <ul parentName="li">
          <li parentName="ul">{`If you must place the accordion area somewhere else, use `}<inlineCode parentName="li">{`aria-owns`}</inlineCode>{` attribute to indicate the relationship. However, be aware that this attribute does not necessarily work with all screen readers.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Make sure that the keyboard user can navigate out of the accordion area without closing the accordion. See `}<ExternalLink href="https://www.w3.org/TR/WCAG21/#no-keyboard-trap" mdxType="ExternalLink">{`WCAG 2.1.2 No Keyboard Trap guideline`}</ExternalLink>{` for more information.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      